<template>
  <div class="pt-16 px-8">
    <v-sheet max-width="460" class="mx-auto" color="bgColor">
      <v-flex class="d-flex justify-center">
        <h1>Forgot your password ?</h1>
      </v-flex>
      <v-form class="pt-8" @submit.prevent="submit">
        <v-text-field
          type="email"
          v-model="email"
          label="Email"
          :rules="emailRules"
        >
        </v-text-field>

        <v-btn
          :loading="loading"
          :disabled="loading || !isValid"
          type="submit"
          color="primary"
          block
          class="mt-2"
        >
          Send password reset link
        </v-btn>

      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import store from "../store";

import { useApi } from "../hooks/useApi";

const userApi = useApi().user

export default {
  data() {
    return {
      loading: false,
      isValid: false,

      email: '',
      password: '',

      emailRules: [(value) => {
        this.checkForm()

        if (!value) return 'Email is required'

        let isValid = /.+@.+\..+/.test(value)
        return isValid
      }],
    };
  },
  methods: {
    checkForm() {
      let isValidEmail = /.+@.+\..+/.test(this.email)
      this.isValid = isValidEmail
    },
    async submit() {
      this.loading = true

      const params = {
        email: this.email,
      }

      try {
        const { success, data, msg } = await userApi.forgot(params);

        if (success) {
          this.$toast(msg, {
            timeout: 3000,
            type: "success"
          });
        } else {
          this.$toast(msg, {
            timeout: 3000,
            type: "warning"
          });
        }
      }
      catch (err) {
        this.$toast("Server error. Please contact your system administrator", {
          timeout: 3000,
          type: "error"
        });
      }
      finally {
        this.loading = false;
      }
    }
  }
};
</script>
