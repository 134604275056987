<template>
  <v-row justify="center">
    <RightModal :showDialog="showDialog" :closeDialog="closeDialog">

      <template v-slot:title>
        Create a new satellite
      </template>

      <v-form ref="form" @submit.prevent="submitForm">
        <v-card-text>
          <v-select
            label="Select your version*"
            v-model="selectedVersion"
            :items="[
              {
                text: 'Version 1',
                value: 1,
              },
              {
                text: 'Version 2',
                value: 2,
              }
            ]"
          ></v-select>

          <v-text-field v-if="selectedVersion === 1"
            label="Call sign*"
            v-model="callSign"
            :rules="callSignRules"
            required
          ></v-text-field>

          <v-text-field v-if="selectedVersion === 2"
            label="Sat ID*"
            v-model="satId"
            :rules="satIdRules"
            required
          ></v-text-field>

          <v-text-field
            label="Name*"
            v-model="name"
            :rules="nameRules"
            required
          ></v-text-field>

          <v-textarea
            label="Description*"
            v-model="description"
            :rules="descriptionRules"
            required
          ></v-textarea>

          <v-select
            label="Select your satellite type"
            v-model="selectedSatelliteType"
            :items="[
              'Experimental', 'Future', 'Supperted', 'Inactive'
            ]"
          ></v-select>

          <v-file-input
            accept="image/png, image/jpeg"
            placeholder="Satellite an image"
            prepend-icon="mdi-camera"
            label="Image*"
            v-model="image"
            v-on:change="changeImage"
            :rules="imgRules"
            required
          ></v-file-input>

          <!-- TODO: Odoo oruulj bgaa zurgiig haruulah -->
          <v-img
            lazy-src="/images/notfound.jpg"
            max-height="150"
            max-width="250"
            :src="imageUrl"
          ></v-img>

          <h2 class="mt-4">Launch date</h2>
          <v-date-picker
            v-model="launchDate"
            @input="updatedLaunchDate"
          ></v-date-picker>

          <h2 class="mt-4">Configurations</h2>
          <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
                <v-text-field v-model="configuration.mode" :rules="configurationRules" label="mode*"></v-text-field>
              </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.freq" :rules="configurationRules" label="freq*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.bw" :rules="configurationRules" label="bw*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.sf" :rules="configurationRules" label="sf*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.cr" :rules="configurationRules" label="cr*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.sw" :rules="configurationRules" label="sw*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.pwr" :rules="configurationRules" label="pwr*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.cl" :rules="configurationRules" label="cl*"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.pl" label="pl"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.gain" label="gain"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.crc" label="crc"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.fldro" label="fldro"></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                lg="2"
              >
              <v-text-field v-model="configuration.NORAD" label="NORAD"></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>


        <v-card-actions class="pb-5 px-4">
          <v-btn
            type="submit"
            color="primary"
            block
            :loading="isSubmitLoading"
            :disabled="isSumbitDisabled"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>

    </RightModal>
  </v-row>
</template>

<script>
  import { getFileFromUrl } from '../../utils';
  import { useApi } from '../../hooks/useApi';

  import RightModal from '@components/Modal/RightModal.vue';

  const satelliteApi = useApi().satellite
  const satelliteV2Api = useApi().v2.satellite

  const INIT_SATELLITE_CONFIGS = {
    "mode": "",
    "freq": "",
    "bw": "",
    "sf": "",
    "cr": "",
    "sw": "",
    "pwr": "",
    "cl": "",
    "pl": "",
    "gain": 0,
    "crc": 0,
    "fldro": 0,
    "NORAD": "",
  }

  export default {
    props: {
      showDialog: Boolean,
      satellite: Object,
    },
    components: {
      RightModal
    },
    data() {
      return {
        isSumbitDisabled: false,
        isSubmitLoading: false,  // submit loader

        selectedVersion: 2,
        name: "",
        callSign: "",
        satId: "",
        description: '',
        selectedSatelliteType: "Experimental",
        launchDate: this.dateToLaunchDate(),
        imageUrl: "",
        image: null,
        configuration: INIT_SATELLITE_CONFIGS,

        nameRules: [
          (value) => {
            this.validateForm();
            if (!value) {
              return 'The name is required'
            }
            return true
          }
        ],
        callSignRules: [
          (value) => {
            this.validateForm();
            if (!value) {
              return 'The call sign is required'
            } else if (value && value.length !== 4) {
              return 'The call sign not equal 4 characters'
            }
            return true
          }
        ],
        satIdRules: [
        (value) => {
            this.validateForm();
            if (!value) {
              return 'The satellite ID is required'
            } else if (value && value.length !== 2) {
              return 'The satellite ID not equal 2 characters'
            } else if (!this.checkSatId(value)) {
              return 'The satellite ID is not hex'
            }

            return true
          }
        ],
        descriptionRules: [
          (value) => {
            this.validateForm();
            if (!value) {
              return 'The description is required'
            }

            return true
          }
        ],
        imgRules: [
          (value) => {
            this.validateForm();
            if (!value) {
              return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
            }

            return true
          }
        ],
        configurationRules: [
          (value) => {
            this.validateForm();
            return true
          }
        ],
      }
    },
    methods: {
      dateToLaunchDate: (value) => {
        let date = null
        if (value) {
          date = new Date(value);
        }
        else {
          date = new Date();
        }
        // Get the year, month, and day
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        // Format the date as 'YYYY-MM-DD'
        return `${year}-${month}-${day}`;
      },
      closeDialog() {
        // form iig hooslono
        this.$refs.form.reset();
        setTimeout(() => {
          this.selectedVersion = 2;
        }, 150);
        this.image = null;
        this.imageUrl = "/images/notfound.jpg";
        this.isSumbitDisabled = true;
        this.isSubmitLoading = false;
        this.$emit('closeDialog'); // Emit event to call the closeModalFromChild function in the parent component
      },
      updatedLaunchDate(newDate) {
        this.launchDate = newDate;
        this.validateForm()
      },
      validateForm() {
        this.isSumbitDisabled = true;
        if (this.selectedVersion === 1)
        {
          if (this.name && this.callSign && this.description && this.image && this.configuration.mode && this.configuration.freq && this.configuration.bw && this.configuration.sf && this.configuration.cr && this.configuration.pwr && this.configuration.cl) {
            this.isSumbitDisabled = false;
          }
        }
        else if (this.selectedVersion === 2)
        {
          if (this.name && this.satId && this.description && this.image && this.configuration.mode && this.configuration.freq && this.configuration.bw && this.configuration.sf && this.configuration.cr && this.configuration.pwr && this.configuration.cl) {
            this.isSumbitDisabled = false;
          }
        }
      },
      changeImage(a) {
        /// solison zurag bku bol shine zurgiig haruulahgv
        if (a) {
          this.imageUrl = URL.createObjectURL(a)
        }
      },
      checkSatId(satId)  {
        const hexRegex = /^[0-9a-fA-F]{1,2}$/;

        if (hexRegex.test(satId)) {
          // Convert the hex string to a decimal number
          const decimalValue = parseInt(satId, 16);
          // Check if the decimal value is between 0 and 255
          return decimalValue >= 0 && decimalValue <= 255;
        }

        return false;
      },
      async submitForm() {
        this.isSubmitLoading = true

        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("callSign", this.callSign);
        formData.append("satId", this.satId);
        formData.append("description", this.description);
        formData.append("launchDate", this.launchDate);
        formData.append("image", this.image);
        formData.append("configuration", JSON.stringify(this.configuration));

        const api = this.selectedVersion === 1
                    ? satelliteApi.post(formData)
                    : satelliteV2Api.post(formData)
        const { success, msg, data }  = await api
        this.isSubmitLoading = false

        if (success) {
          this.$toast(msg, {
            timeout: 3000,
            type: "success"
          });
          this.closeDialog();
        } else {
          this.$toast(msg, {
            timeout: 3000,
            type: "warning"
          });
        }
      }
    },
    watch: {
      selectedVersion() {
        if (this.selectedVersion === 1)
        {
          this.satId = undefined;
        }
        else if (this.selectedVersion === 2)
        {
          this.callSign = undefined;
        }
      }
    }

  }

</script>
