<template>
  <v-dialog v-model="showDialog" persistent>
    <v-card min-height="100%" class="px-2 pb-5">

      <!-- Card title -->
      <v-card-title>
        <span class="headline">Message Details</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Card body -->
      <v-card-text class="mt-15">
        <p>{{ message }}</p>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    showDialog: Boolean,
    message: {
      type: String,
      required: true
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
  }
}
</script>
