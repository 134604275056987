<template>
<div class="packet">
  <v-container>

    <v-layout v-if="isLoading && !packet">
      <v-row class="fill-height ma-16" align="center" justify="center">
        <v-progress-circular indeterminate color="grey"></v-progress-circular>
      </v-row>
    </v-layout>
    <v-layout v-else-if="isLoading && !packet">
      <h2 class="pt-4">Data not found</h2>
    </v-layout>

    <v-layout row wrap>
      <v-flex pa-4 xs12 sm6 v-if="packet && packet.version === 1">
        <PacketVersion1 :packet="packet" />
      </v-flex>

      <v-flex pa-4 xs12 sm6 v-else-if="packet && packet.version == 2">
        <PacketVersion2 :packet="packet" />
      </v-flex>

      <v-flex pa-4 xs12 sm6 class="grey--text text--darken-1">
        <v-card flat class="mr-5 my-3 pa-4 grey--text text--darken-3">
          <PacketMap v-if="packet" :packet="packet" />
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex sm12 md6>
        <v-card flat class="mr-5 my-3 grey--text text--darken-3">
          <v-card-text class="mx-auto">
          <a class="download-btn" :href="downloadUrl" download="packet.bin">Download</a>
            <h2 class="ma-2">Hexadecimal view</h2>
            <p>
              {{ base64ToHex(packet?.data) }}
            </p>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex sm12 md6>
        <v-card v-if="packet?.payload" flat class="mr-5 my-3 pa-2 grey--text text--darken-3" style="overflow:auto;white-space: nowrap;max-height:600px;">
          <v-card-text class="mx-auto">
            <h2 class="ma-2 mb-5">Raw parsed view</h2>
            <json-viewer  copyable :expand-depth=0 boxed  :value="packet?.payload"></json-viewer>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- <v-flex xs12 sm12 pa-4>
      <div v-for="station in packet.stations" :key="`${station.name}@${station.userId}`">
        <StationRx :station="station" :freq="packet.frequency"/>
        <v-divider></v-divider>
      </div>
    </v-flex> -->
  </v-container>
</div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';

import PacketMap from '../components/PacketMap.vue';
import PacketVersion1 from './packet/PacketVersion1.vue';
import PacketVersion2 from './packet/PacketVersion2.vue';

import "leaflet/dist/leaflet.css"

import { useApi } from "../hooks/useApi"
import { dateToMyTimeZone } from '../utils/myDate';

const packetAPI = useApi().packet

export default {
  name: "Packet",
  components: {
    PacketMap,
    PacketVersion1,
    PacketVersion2,
    JsonViewer,
  },
  data() {
    return {
      isLoading: false,
      packet: null,
    }
  },
  beforeMount() {
    this.getPacket()
  },
  methods: {
    async getPacket() {
      this.isLoading = true;
      const { success, data } = await packetAPI.getOnce(this.$route.params.id)
      if (success) {
        this.packet = data;
      }
      this.isLoading = false;
    },
    formatDate(time) {
      return dateToMyTimeZone(time);
    },
    base64ToHex(str) {
      if (!str)
        return ""

      const raw = atob(str);
      let result = '';
      for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += " " + (hex.length === 2 ? hex : '0' + hex);
      }
      return result
    },
    computeAntDeployAttempt(antDeployAttempt) {
      if (!antDeployAttempt) {
        return
      }
      return parseInt(antDeployAttempt, 2);
    },
    computeRFInterval(rfInterval) {
      if (!rfInterval) return

      let _rfInterval = ''
      try {
        _rfInterval = parseInt(parseInt(rfInterval, 2)) * 2
      } catch (err) {}

      return _rfInterval
    },
    computeBoardTemp(boardTemp) {
      try {
        return boardTemp - 100;
      }
      catch {
        return ''
      }
    }
  },
  computed: {
    downloadUrl() {
      return `data:application/octet-stream;base64,${this.packet?.data}`
    },
    p() {
      let packet = this.packet;
      if (packet && packet.parsed && packet.parsed.payload)
        return packet.parsed.payload
      return null
    },
  },
}
</script>

<style>
.jv-container .jv-code {
  padding: 4px 10px;
}

.download-btn {
  position:absolute;
  right:20px;
  text-decoration: none;
  color:rgb(84, 176, 212) !important;
}
</style>
