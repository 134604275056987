<template>
  <div>
    <v-card flat class="mr-5 my-3 pa-2">
      <v-card-text class="mx-auto">
        <h1 class="ma-2 mb-5">{{ packet.satellite.name }}</h1>
        <div>Received on: {{ formatDate(packet.serverTime) }}</div>
        <div>
          Mode: <i>{{ packet.satellite?.configuration?.mode }}</i> {{ packet.satellite?.configuration?.freq }} Mhz  SF: {{ packet.satellite?.configuration?.sf }}  CR: {{ packet.satellite?.configuration?.cr }} BW: {{ packet.satellite?.configuration?.bw }} kHz BW: {{ packet.satellite?.configuration?.bw }} kHz
        </div>
        <div v-if="packet.payload">
          <br />
          <DynamicTemplate class="pa-2" :packet="packet" />
          <v-layout row wrap>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>🔄 OBC reset: {{ parseInt(payload?.byte_3) }}</div>
              <div>⬆️ Last CMD: {{ payload?.byte_4 }}</div>
              <div>RF SF: {{ parseInt(payload?.byte_45, 16) }}</div>
              <div v-if="payload?.byte_46 === '01'">RF Bandwidth: 250KHz</div>
              <div v-else-if="payload?.byte_46 === '02'">RF Bandwidth: 125KHz</div>
              <div v-else-if="payload?.byte_46 === '03'">RF Bandwidth: 62KHz</div>
              <div>Data collect interval: {{ parseInt(payload?.byte_47, 16) }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>Packet byte 1: {{ payload?.byte_5 }}</div>
              <div>Packet byte 2: {{ payload?.byte_6 }}</div>
              <div>Packet byte 3: {{ payload?.byte_7 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>ADCS state: {{ payload?.byte_8 }}</div>
              <div>gyro x_1: {{ payload?.byte_9 }}</div>
              <div>gyro x_2: {{ payload?.byte_10 }}</div>
              <div>gyro y_1: {{ payload?.byte_11 }}</div>
              <div>gyro y_2: {{ payload?.byte_12 }}</div>
              <div>gyro z_1: {{ payload?.byte_13 }}</div>
              <div>gyro z_2: {{ payload?.byte_14 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>acc x_1: {{ payload?.byte_15 }}</div>
              <div>acc x_2: {{ payload?.byte_16 }}</div>
              <div>acc y_1: {{ payload?.byte_17 }}</div>
              <div>acc y_2: {{ payload?.byte_18 }}</div>
              <div>acc z_1: {{ payload?.byte_19 }}</div>
              <div>acc z_2: {{ payload?.byte_20 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>mag x_1: {{ payload?.byte_21 }}</div>
              <div>mag x_2: {{ payload?.byte_22 }}</div>
              <div>mag y_1: {{ payload?.byte_23 }}</div>
              <div>mag y_2: {{ payload?.byte_24 }}</div>
              <div>mag z_1: {{ payload?.byte_25 }}</div>
              <div>mag z_2: {{ payload?.byte_26 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>sun +x_1: {{ payload?.byte_27 }}</div>
              <div>sun +x_2: {{ payload?.byte_28 }}</div>
              <div>sun -x_1: {{ payload?.byte_29 }}</div>
              <div>sun -x_2: {{ payload?.byte_30 }}</div>
              <div>sun +y_1: {{ payload?.byte_31 }}</div>
              <div>sun +y_2: {{ payload?.byte_32 }}</div>
              <div>sun -y_1: {{ payload?.byte_33 }}</div>
              <div>sun -y_2: {{ payload?.byte_34 }}</div>
              <div>sun +z_1: {{ payload?.byte_35 }}</div>
              <div>sun +z_2: {{ payload?.byte_36 }}</div>
              <div>sun -z_1: {{ payload?.byte_37 }}</div>
              <div>sun -z_2: {{ payload?.byte_38 }}</div>
            </v-flex>
            <v-flex pa-2 xs6 md6 lg4 xl3>
              <div>GPS lat_h: {{ payload?.byte_39 }}</div>
              <div>GPS lat_l: {{ payload?.byte_40 }}</div>
              <div>GPS long_h: {{ payload?.byte_41 }}</div>
              <div>GPS long_l: {{ payload?.byte_42 }}</div>
              <div>GPS sign: {{ payload?.byte_43 }}</div>
              <div>GPS hour: {{ payload?.byte_44 }}</div>
              <div>GPS minute: {{ payload?.byte_45 }}</div>
            </v-flex>
          </v-layout>
        </div>
        <div v-else>
          <strong>Unrecognized packet.</strong> This packet does not match the known structure of this satellite. It might be an unknown packet or just terrestrial noise.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import DynamicTemplate from '@components/DynamicTemplate.vue';

  import { dateToMyTimeZone } from '@utils/myDate';

  export default {
    name: "PacketVersion2",
    props: ['packet'],
    components: {
      DynamicTemplate,
    },
    methods: {
      formatDate(time) {
        return dateToMyTimeZone(time);
      },
      base64ToHex(str) {
        const raw = atob(str);
        let result = '';
        for (let i = 0; i < raw.length; i++) {
          const hex = raw.charCodeAt(i).toString(16);
          result += " " + (hex.length === 2 ? hex : '0' + hex);
        }
        return result
      },
      computeAntDeployAttempt(antDeployAttempt) {
        if (!antDeployAttempt) {
          return
        }
        return parseInt(antDeployAttempt, 2);
      },
      computeRFInterval(rfInterval) {
        if (!rfInterval) return

        let _rfInterval = ''
        try {
          _rfInterval = parseInt(parseInt(rfInterval, 2)) * 2
        } catch (err) {}

        return _rfInterval
      },
      computeBoardTemp(boardTemp) {
        try {
          return boardTemp - 100;
        }
        catch {
          return ''
        }
      }
    },
    computed: {
      base64ToHexDecimal() {
        return `data:application/octet-stream;base64,${this.packet.raw}`
      },
      payload() {
        let packet = this.packet;
        if (packet && packet.payload)
          return packet.payload
        return null
      },
    },
  }
</script>

<style>
  .jv-container .jv-code {
    padding: 4px 10px;
  }

  .download-btn {
    position:absolute;
    right:20px;
    text-decoration: none;
    color:rgb(84, 176, 212) !important;
  }
</style>
