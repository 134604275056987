import router from '../../router'

import { useApi } from '../../hooks/useApi';

const userApi = useApi().user

const state = {
  isAuthenticated: false,
  user: {},
};

const getters = {
  getIsAuthenticated: state => state.isAuthenticated,
  getUser: state => state.user,
};

const actions = {
  async checkAuth({ commit }) {
    try {
      const { success, data } = await userApi.logged()
      if (success) {
        window.localStorage.setItem("stoken", data?.token)

        commit('setIsAuthenticated', success)
        commit('setUser', data)
        if (window.location.pathname === '/') {
          router.push('/home')
        }

      }
      else
      {
        // auth hereggv huudas bwal usergehgv
        if (router.currentRoute?.meta?.requireAuth)
          router.push('/')
      }
    } catch (e) {

    }
  },
  async logOut({ commit }) {
    try {
      await userApi.logout()
      commit('setAuthLogout')
    } catch (err) {

    }
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated
  },
  setAuthLogout(state) {
    window.localStorage.removeItem("stoken")
    state.isAuthenticated = false
    state.user = {}
    router.push('/')
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
