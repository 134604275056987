<template>
  <div class="pt-16 px-8">
    <v-sheet max-width="460" class="mx-auto" color="bgColor">
      <v-flex class="d-flex justify-center">
        <h1>Login</h1>
      </v-flex>
      <v-form class="pt-8" @submit.prevent="submit">
        <v-text-field
          type="email"
          v-model="email"
          label="Email"
          :rules="emailRules"
        >
        </v-text-field>

        <v-text-field
          type="password"
          v-model="password"
          label="Password"
          :rules="passwordRules"
        >
        </v-text-field>

        <!-- TODO: Forgot password -->
        <a href="/forgot-password" class="text-body-2 font-weight-regular">Forgot Password?</a>

        <v-btn
          :loading="loading"
          :disabled="loading || !isValid"
          type="submit"
          color="primary"
          block
          class="mt-2"
        >
          Sign in
        </v-btn>

      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import store from "../store";

import { useApi } from "../hooks/useApi";

const userApi = useApi().user

export default {
  data() {
    return {
      loading: false,
      isValid: false,

      email: '',
      password: '',

      emailRules: [(value) => {
        this.checkForm()

        if (!value) return 'Email is required'

        let isValid = /.+@.+\..+/.test(value)
        return isValid
      }],
      passwordRules: [(value) => {
        this.checkForm()

        if (value) return true

        return false
      }]
    };
  },
  methods: {
    checkForm() {
      let isValidEmail = /.+@.+\..+/.test(this.email)
      let isValidPassword = false

      if (this.password) isValidPassword = true

      this.isValid = isValidEmail && isValidPassword
    },
    async submit() {
      this.loading = true

      const params = {
        email: this.email,
        password: this.password
      }

      try {
        const { success, data, msg } = await userApi.login(params);

        if (success) {
          window.localStorage.setItem("stoken", data?.token)
          store.dispatch('setIsAuthenticated', success)
          store.dispatch('setUser', data)
          window.location = '/home'
        } else {
          this.$toast(msg, {
            timeout: 3000,
            type: "warning"
          });
        }
      }
      catch (err) {
        this.$toast("Server error. Please contact your system administrator", {
          timeout: 3000,
          type: "error"
        });
      }
      finally {
        this.loading = false;
      }
    }
  }
};
</script>
