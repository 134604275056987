import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

// Data table
import VueEasytable from "vue-easytable";
import "vue-easytable/libs/theme-dark/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


store.dispatch('checkAuth');

Vue.config.productionTip = false

Vue.use(Toast);
Vue.use(VueEasytable);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
