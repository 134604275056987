import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store'

import Home from '../views/Home.vue'
import User from '../views/User.vue'
import Users from '../views/Users.vue'
import ApproveUser from '../views/user/ApproveUser.vue'

import Login from '../views/Login.vue'
import Packet from '../views/Packet.vue'
import Packets from '../views/Packets.vue'
import Station from '../views/Station.vue'
import Satellite from '../views/Satellite.vue'
import Satellites from '../views/Satellites.vue'
import RockBlock from '../views/rockBlock/RockBlocks.vue'
import CommandLogs from '../views/commandLog/CommandLogs.vue'
import AprilFools from '../views/AprilFools.vue'
import ForgetPassword from '@views/ForgetPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Login-OndoGS'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      requiresAuth: false,
      title: 'OndoGS'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      title: 'Home - OndoGS'
    }
  },
  {
    path: '/stations',
    name: 'Stations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Stations.vue'),
    meta: {
      requiresAuth: true,
      title: 'Stations - OndoGS'
    }
  },
  {
    path: '/satellites',
    name: 'Satellites',
    component: Satellites,
    meta: {
      requiresAuth: true,
      title: 'Satellites - OndoGS'
    }
  },
  {
    path: '/satellite/:id',
    name: 'Satellite',
    component: Satellite,
    meta: {
      requiresAuth: true,
      title: 'Satellite - OndoGS'
    }
  },
  {
    path: '/packet/:id',
    name: 'Packet',
    component: Packet,
    meta: {
      requiresAuth: true,
      title: 'Packet View - OndoGS'
    }
  },
  {
    path: '/packets',
    name: 'Packets',
    component: Packets,
    meta: {
      requiresAuth: true,
      title: 'Last received packets - OndoGS'
    }
  },
  {
    path: '/station/:id',
    name: 'Station',
    component: Station,
    meta: {
      requiresAuth: true,
      title: 'Station Console - OndoGS'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      title: 'Users - OndoGS'
    }
  },
  {
    path: '/user/:id',
    name: 'User',
    component: User,
    meta: {
      requiresAuth: true,
      title: 'User Console - OndoGS'
    }
  },
  {
    path: '/user/confirm/:token',
    name: 'ApproveToken',
    component: ApproveUser,
    meta: {
      requiresAuth: false,
      title: 'Approve User - OndoGS'
    }
  },
  {
    path: '/rock-block',
    name: 'Rock-Block',
    component: RockBlock,
    meta: {
      requiresAuth: true,
      title: 'Rock Block - OndoGS'
    }
  },
  {
    path: '/april/:id',
    name: 'AprilFools',
    component: AprilFools,
    meta: {
      requiresAuth: true,
      title: 'April Fools - OndoGS'
    }
  },
  {
    path: '/command-log',
    name: 'CommandLogs',
    component: CommandLogs,
    meta: {
      requiresAuth: true,
      title: 'Command Logs - OndoGS'
    }
  },
  // {
  //   path: '/command-log/:id',
  //   name: 'CommandLog',
  //   component: AprilFools,
  //   meta: {
  //     requiresAuth: true,
  //     title: 'April Fools - OndoGS'
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, _, next) => {
  document.title = to.meta.title
  if (to.meta.requiresAuth && !store.getters.getIsAuthenticated) next({ name: 'Login' })
  else next()
})

export default router
