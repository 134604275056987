<template>
  <div>
    <v-flex xs12>
      <v-card flat class="mr-5 my-3 pa-2">
        <v-card-text class="mx-auto">
          <h1 class="ma-2 mb-5">{{ packet?.satellite?.name }}</h1>
          <div>Received on: {{ formatDate(packet.serverTime) }}</div>
          <div>
            Mode: <i>{{packet.mode}}</i> {{packet.frequency}} Mhz  SF: {{packet.sf}}  CR: {{packet.cr}} BW: {{packet.bw}} kHz BW: {{packet.bw}} kHz
          </div>

          <div v-if="packet">
            <br />
            <DynamicTemplate class="pa-2" :packet="packet" />
            <v-layout row wrap>
              <v-flex pa-2 xs12 sm6 md4>
                <!-- Antenna Deploy -->
                <div v-if="p?.byte3?.antDepl === true">🛰 Antenna Deploy:&nbsp;
                  <v-icon class="green--text">mdi-circle</v-icon>
                </div>
                <div v-else>🛰 Antenna Deploy:&nbsp;
                  <v-icon class="grey--text">mdi-circle</v-icon>
                </div>
                <!-- OBC reset time -->
                <div>🔄 OBC reset counter: {{p?.obcRst}}</div>
                <!--  Last Up Link Command -->
                <div>Last Up Link Command 🆔: {{ p?.lstCmdId }}</div>
                <!--  Antenna deploy attempt -->
                <div>Antenna deploy attempt: {{ p?.byte3?.depAtm }}</div>
                <div>RF interval: {{ p?.byte9?.rfInt }}</div>
                <!-- Board temperature -->
                <div>Board temperature: {{ p?.batTemp }} ºC</div>
              </v-flex>
              <v-flex pa-2 xs12 sm6 md4>
                <!-- up link -->
                <div v-if="p?.byte9?.uplink === true">Up Link connection:&nbsp;
                  <v-icon class="green--text">mdi-power-plug</v-icon>
                </div>
                <div v-else>Up Link connection:&nbsp;
                  <v-icon class="grey--text">mdi-power-plug-off</v-icon>
                </div>
                <!-- com to main flag -->
                <div v-if="p?.byte9?.com2main === true">Com to OBC connection:&nbsp;
                  <v-icon class="green--text">mdi-power-plug</v-icon>
                </div>
                <div v-else>Com to OBC connection:&nbsp;
                  <v-icon class="grey--text">mdi-power-plug-off</v-icon>
                </div>
                <!-- reset to main flag -->
                <div v-if="p?.byte9?.rst2main === true">Reset to OBC connection:&nbsp;
                  <v-icon class="green--text">mdi-power-plug</v-icon>
                </div>
                <div v-else>Reset to OBC connection:&nbsp;
                  <v-icon class="grey--text">mdi-power-plug-off</v-icon>
                </div>
                <!-- EPS to main flag -->
                <div v-if="p?.byte9?.eps2main === true">EPS to OBC connection:&nbsp;
                  <v-icon class="green--text">mdi-power-plug</v-icon>
                </div>
                <div v-else>EPS to OBC connection:&nbsp;
                  <v-icon class="grey--text">mdi-power-plug-off</v-icon>
                </div>
              </v-flex>
              <v-flex pa-2 xs12 sm6 md4>
                <!--  Solar -X -->
                <div v-if="p?.byte3?.solarXn === true">Solar -X:&nbsp;
                  <v-icon class="yellow--text">mdi-white-balance-sunny</v-icon>
                </div>
                <div v-else>Solar -X:&nbsp;
                  <v-icon>mdi-weather-night</v-icon>
                </div>
                <!--  Solar +Y -->
                <div v-if="p?.byte3?.solarYp === true">Solar +Y:&nbsp;
                  <v-icon class="yellow--text">mdi-white-balance-sunny</v-icon>
                </div>
                <div v-else>Solar +Y:&nbsp;
                  <v-icon>mdi-weather-night</v-icon>
                </div>
                <!--  Solar -Y -->
                <div v-if="p?.byte3?.solarYn === true">Solar -Y:&nbsp;
                  <v-icon class="yellow--text">mdi-white-balance-sunny</v-icon>
                </div>
                <div v-else>Solar -Y:&nbsp;
                  <v-icon>mdi-weather-night</v-icon>
                </div>
                <!--  Solar +Z -->
                <div v-if="p?.byte3?.solarZp === true">Solar +Z:&nbsp;
                  <v-icon class="yellow--text">mdi-white-balance-sunny</v-icon>
                </div>
                <div v-else>Solar +Z:&nbsp;
                  <v-icon>mdi-weather-night</v-icon>
                </div>
                <!--  Solar -Z -->
                <div v-if="p?.byte3?.solarZn === true">Solar -Z:&nbsp;
                  <v-icon class="yellow--text">mdi-white-balance-sunny</v-icon>
                </div>
                <div v-else>Solar -Z:&nbsp;
                  <v-icon>mdi-weather-night</v-icon>
                </div>
              </v-flex>
            </v-layout>

          </div>
          <div v-else>
            <strong>Unrecognized packet.</strong> This packet does not match the known structure of this satellite. It might be an unknown packet or just terrestrial noise.
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </div>
</template>

<script>
  import DynamicTemplate from '@components/DynamicTemplate.vue';

  import { dateToMyTimeZone } from '@utils/myDate';

  export default {
    name: "PacketVersion2",
    props: ['packet'],
    components: {
      DynamicTemplate,
    },
    methods: {
      downloadUrl() {
        return `data:application/octet-stream;base64,${this.packet.raw}`
      },
      formatDate(time) {
        return dateToMyTimeZone(time);
      },
      base64ToHex(str) {
        const raw = atob(str);
        let result = '';
        for (let i = 0; i < raw.length; i++) {
          const hex = raw.charCodeAt(i).toString(16);
          result += " " + (hex.length === 2 ? hex : '0' + hex);
        }
        return result
      },
      computeAntDeployAttempt(antDeployAttempt) {
        if (!antDeployAttempt) {
          return
        }
        return parseInt(antDeployAttempt, 2);
      },
      computeRFInterval(rfInterval) {
        if (!rfInterval) return

        let _rfInterval = ''
        try {
          _rfInterval = parseInt(parseInt(rfInterval, 2)) * 2
        } catch (err) {}

        return _rfInterval
      },
      computeBoardTemp(boardTemp) {
        try {
          return boardTemp - 100;
        }
        catch {
          return ''
        }
      }
    },
    computed: {
      base64ToHexDecimal() {
        return `data:application/octet-stream;base64,${this.packet.raw}`
      },
      p() {
        let packet = this.packet;
        if (packet && packet.payload)
          return packet.payload
        return null
      },
    },
  }
</script>

<style>
  .jv-container .jv-code {
    padding: 4px 10px;
  }

  .download-btn {
    position:absolute;
    right:20px;
    text-decoration: none;
    color:rgb(84, 176, 212) !important;
  }
</style>
