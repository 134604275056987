import moment from 'moment';

/**
 * Хугацааны утгыг string date руу хөрвүүлэх
 * @param {Date} date хугацааны утгыг явуулна
 * @returns date
 */
export function dateToMyTimeZone(date) {
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
}
