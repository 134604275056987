<template>
  <v-card flat class="pa-7 clickable" :to="`/packet/${packet._id}`">
    <v-layout row wrap>
      <v-flex xs12 sm6 md6 lg3 xl3>
        <h3 class="mr-2"><v-icon>$satelliteDark</v-icon> {{packet.satellite?.name ? packet.satellite?.name : "Unknown"}}</h3>
        <div>{{`${dateFormat(packet.serverTime)} (${dateSince(packet.serverTime)})`}}</div>
      </v-flex>
      <v-flex xs12 sm3 md3 lg2 xl2>
        <div class="caption grey--text">Mode</div>
        <div v-if="packet.version === 1">{{`${packet.mode}@${packet.frequency}`}}</div>
        <div v-else-if="packet.version === 2">{{`${packet?.satellite?.configuration?.mode}@${packet?.satellite?.configuration?.freq}`}}</div>
      </v-flex>
      <v-flex xs12 sm12 md6 lg5 xl5 v-if="packet.payload">
        <DynamicTemplate class="pa-3" :packet="packet" />
      </v-flex>
      <v-flex xs12 sm12 md6 lg5 xl5 v-else>
        <div class="caption grey--text">Data</div>
        {{ toHex(packet.data).substring(0, 30) }}
      </v-flex>
      <v-flex xs12 sm3 md3 lg2 xl2>
        <div class="caption grey--text">Received by</div>
        <div v-if="packet?.stationNumber > 1">{{ packet.stationNumber }} stations</div>
        <div v-else>{{ packet?.stationNumber }} station</div>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import moment from 'moment';

import { dateToMyTimeZone } from '../utils/myDate';

import DynamicTemplate from './DynamicTemplate.vue'

export default {
  components: {
    DynamicTemplate
  },
  name: "PacketRow",
  props: [
    "packet",
  ],
  methods: {
    dateFormat(time) {
      return dateToMyTimeZone(time);
    },
    dateSince(time) {
      return moment(time).fromNow()
    },
    toHex(data ) {
      let packetData = "";
      if (!data)
      {
        return packetData;
      }
      if (data.startsWith("VGlueUdTLXRlc3Q"))
        return "OndoGS Test Packet"

      if (data.startsWith("RXJyb3JfQ1JD"))
        return "CRC ERROR"

      var decodedData = Buffer.from(data, 'base64')
      for (var i = 0; i < decodedData.length; i++) {
          if (decodedData[i] <= 0xF) { packetData += "0"; }
          else { packetData += ""; }
          packetData += decodedData[i].toString(16) + "";
      }
      return packetData;
    }
  }
}
</script>

<style>

</style>
