<template>
  <div class="create-rockblock">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="500"
    >
    <v-card min-height="100%" class="px-2 pb-5">
      <v-form min-height="100%" @submit.prevent="handleSubmit">

        <!-- Card title -->
        <v-card-title>
          <span class="headline">Create new rockblock</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Card body -->
        <v-card-text>
          <v-select
            label="Device"
            v-model="selectedDevice"
            :items="rockBlockDevices"
            item-text="name"
            item-value="imei"
          ></v-select>

          <v-textarea
            label="Plain text"
            v-model="text"
            required
          ></v-textarea>
        </v-card-text>

        <!-- Submit Button -->
        <v-card-actions>
          <v-row
            justify="center"
          >
            <v-btn type="submit" :disabled="!selectedDevice || !text" color="primary">Submit</v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { useApi } from '../../hooks/useApi';

  const rbSendMessageAPI = useApi().rockBlock.sendMessage

  export default {
    props: {
      showDialog: Boolean
    },
    data() {
      return {
        text: '',

        // rockBlock
        selectedDevice: null,
        rockBlockDevices: [
          {
            name: "RockBLOCK 212804",
            imei: "300434067072070",
          },
          {
            name: "FM-1",
            imei: "300434067079080",
          },
          {
            name: "FM-2",
            imei: "300434067078130",
          }
        ],
      }
    },
    methods: {
      closeDialog() {
        this.text = '';
        this.selectedDevice = '';

        this.$emit("closeDialog");
      },
      async handleSubmit() {
        let body = {
          imei: this.selectedDevice,
          data: this.text
        }

        const { success, msg } = await rbSendMessageAPI.post(body);

        if (success) {
          this.$toast(msg, {
            timeout: 4000,
            type: "success"
          });

          this.text = '';
          this.selectedDevice = '';

          this.closeDialog();
        } else {
          this.$toast(msg, {
            timeout: 4000,
            type: "warning"
          });
        }
      },
    }
  }
</script>
