<template>
  <v-dialog v-model="modal" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 success" slot="activator" v-on="on">GSM configuration</v-btn>
    </template>

    <v-card>
      <v-card-title><h2>GSM Configurations</h2></v-card-title>
      <v-card-text class="text-center">
        <v-layout row wrap>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Number</div>
            <div>{{ gsmNumber }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Type</div>
            <div>{{ gsmType }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Pass</div>
            <div>{{ gsmPass }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Negj</div>
            <div>{{ gsmData?.negj }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Left data</div>
            <div>{{ unitConv(gsmData?.cccdata?.[0]?.value) }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Data expire time</div>
            <div>{{ gsmData?.cccdata?.[0]?.expire }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <div class="caption grey--text">Expire Time</div>
            <div>{{ gsmData?.expireDate }}</div>
          </v-flex>
          <v-flex pa-2 xs6 sm6 md6 lg3 xl3>
            <v-btn :disabled="loading" @click="updateOne()" color="secondary">Update</v-btn>
          </v-flex>
        </v-layout>
        <hr class="my-5" />
        <v-form>
          <v-text-field autocomplete="false" :disabled="loading" v-model="newNumber" label="GSM Number"></v-text-field>
          <v-text-field autocomplete="false" :disabled="loading" v-model="newPass" type="text" label="GSM Pass"></v-text-field>
          <v-select :disabled="loading" v-model="newType" :items="types" label="Select a gsm type"></v-select>
          <v-btn @click="send()" color="primary">Save</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useApi } from '../hooks/useApi';
import { unitToShort } from '../utils';
const stationApi = useApi().station

export default {
  name: "GSMStation",
  props: [
    "gsmData",
    "gsmNumber",
    "gsmType",
    "gsmPass",
    "sId"
  ],
  data(){
    return {
      loading: false,
      newNumber: this.gsmNumber,
      newType: this.gsmType,
      newPass: this.gsmPass,
      modal: null,
      types: ["Unitel"]
    }
  },
  beforeMount() {
  },
  methods: {
    unitConv(number) {
      return unitToShort(number)
    },
    async updateOne() {
      this.loading = true
      const { success, data } = await stationApi.updateGSMData(this.sId)
      if (success) {
        this.gsmData = data
      }
      this.loading = false
    },
    async send() {
      this.loading = true
      let params = {
        gsmNumber: this.newNumber,
        gsmPass: this.newPass,
        gsmType: this.newType,
      }
      try {
        const { success, data } = await stationApi.updateStation(this.sId, params)
        if (success) {
          this.gsmNumber = this.newNumber
          this.gsmType = this.newType
          this.gsmPass = this.newPass
        }
        else {
          this.newNumber = this.gsmNumber
          this.newType = this.gsmType
          this.newPass = this.gsmPass
        }
      } catch (err) {
        console.log(JSON.stringify(err))
        this.newNumber = this.gsmNumber
        this.newPass = this.gsmPass
        this.newType = this.gsmType
      }
      this.loading = false
      this.modal = false
    }
  }
}
</script>

<style>

</style>
