<template>
  <div fluid class="ma-0 pa-0">
    <Worldmap style="height:85vh; width:100%;" />
  </div>
</template>

<script>
import Worldmap from '../components/Worldmap.vue';

export default {
  name: 'Home',
  components: {
    Worldmap
  },
  data() {
    return {

    }
  },
  beforeMount() {
    // this.getStatistics()
  },
  methods: {
    // async getStatistics() {
    //   const { data } = await axios.get("https://api.tinygs.com/v1/statistics");
    //   // console.log(data);
    //   this.statistics = data;
    // }
  }

}
</script>

<style scoped>
.home {
  height: 100%;
}

h2 {
  text-align: center;
}
</style>
