<template>
  <v-dialog v-model="modal" max-width="540px">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 primary" slot="activator" v-on="on">Static commands</v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis">
          Static commands
        </div>
        <v-btn density="compact" icon @click="handleModalClose()">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <!-- Only hex -->
        <v-select v-model="satellite" :items="satellites" label="Select a satellite*"></v-select>
        <v-row class="pb-4" align="center" justify="center">
          <v-btn v-for="command in commands" :key="command.commandId" outlined color="primary" class="ma-2 white--text" :disabled="satellite==='' || isSubmiting===true" @click="sendStaticCommand(command.commandId)">
            {{ command.command }}
            <v-icon right>mdi-access-point</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useApi } from '@hooks/useApi';

const API = useApi();


export default {
  name: "StaticCommand",
  props: [
    "station",
    "modemConf"
  ],
  data() {
    return {
      modal: null,
      satellite: '',
      config: '',
      satellites: null,
      commands: [],
      isSubmiting: false,
    }
  },
  beforeMount() {
    this.getVersion2Satellites();
    this.getStaticCommands();
  },
  methods: {
    async getVersion2Satellites() {
      const { success, data } = await API.v2.satellite.get();

      if (success)
      {
        this.satellites = data.map(satellite => (
          {
            text: satellite.name,
            value: satellite._id
          }
        ));
      }
    },
    async getStaticCommands() {
      const { success, data } = await API.command.get('static');
      if (success)
      {
        this.commands = data;
      }
    },
    async sendStaticCommand(commandId='') {
      this.isSubmiting = true;

      let params = {
        satelliteId: this.satellite,
        commandId,
      }

      if (!this.satellite)
      {
        this.$toast("Select a satellite", {
          timeout: 3000,
          type: "warning",
        })
        return
      }
      try {
        await API.station.sendStaticCommand(this.$route.params.id, params)
        this.$toast("The command was sent", {
          timeout: 3000,
          type: "success"
        });
      } catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.isSubmiting = false;
      this.satellite = '',
      this.$emit("sent");
      this.modal = false
    },
    handleModalClose() {
      this.isSubmiting = false;
      this.satellite = '',
      this.$emit("sent");
      this.modal = false
    }
  }
}
</script>

<style>

</style>
