<template>
  <div>
    <l-map @popupclose="focusSatellite = null" :zoom="zoom" :center="center" :maxBounds="maxBounds" :minZoom="5"
      style="height: 100%; width: 100%; z-index:0;">
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-marker v-for="satellite in satellites" :key="satellite.name" :lat-lng="[satellite.lat, satellite.lng]"
        :icon="satelliteIcon" @click="focusSatellite = satellite.name">
        <l-popup>
          <h3><router-link :to='`/satellite/${satellite.name}`'>{{ satellite.name }}</router-link></h3>Showing
          stations currently<br>listening to this satellite.
        </l-popup>
        <l-tooltip :class="focusSatellite ? 'hidden' : ''" :options="{ permanent: true, offset: [12, 0], opacity: 0.8 }">
          {{ satellite.name }} </l-tooltip>
      </l-marker>

      <l-marker v-for="station in stations" :key="`${station.name}@${station.userId}`"
        :visible="!focusSatellite || focusSatellite == station.satellite" :lat-lng="station.location"
        :icon="(station.status == 0) ? stationInactiveIcon : stationActiveIcon"
        :class="(station.status == 0) ? 'inactive' : 'active'">

        <l-popup>
          <h3><router-link :to='`/station/${station.name}@${station.userId}`'>{{ station.name }}</router-link></h3><br>
          <strong>{{ (station.status == 0) ? 'Last seen' : 'Last packet' }}:</strong>
          {{ (station.status == 0) ? formatDate(station.lastSeen) : formatDate(station.lastPacketTime) }} <br>
          <strong>Version:</strong> {{ station.version }} <br>
          <strong>Status:</strong> {{ (station.status == 0) ? 'Offline' : 'Online' }} <br>
          <strong>Listening:</strong> {{ station.satellite }} <br>
        </l-popup>

      </l-marker>
    </l-map>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LPopup,
  LMarker,
  LTooltip
} from "vue2-leaflet";

import { icon, latLngBounds } from "leaflet";

import moment from 'moment'

export default {
  name: "Worldmap",
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    LTooltip
  },
  props: [
    "packet"
  ],
  data() {
    return {
      satelliteIcon: icon({
        iconUrl: 'https://static.tinygs.com/satellite_blue.png',
        iconSize: [32, 32], // size of the icon
        iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
      }),
      stationActiveIcon: icon({
        iconUrl: 'https://static.tinygs.com/station_icon_green.png',
        iconSize: [32, 32], // size of the icon
        iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
      }),
      stationInactiveIcon: icon({
        iconUrl: 'https://static.tinygs.com/station_icon_red.png',
        iconSize: [32, 32], // size of the icon
        iconAnchor: [16, 16], // point of the icon which will correspond to marker's location
      }),
      zoom: 3,
      center: [47.15984, 103.798828],
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      stations: null,
      satellites: null,
      focusSatellite: null,
      maxBounds: latLngBounds([
        [180, -180],
        [-180, 180],
      ]),
    };
  },
  beforeMount() {
    this.getSatellites()
    this.getStations()
    //setInterval(this.getSatellites, 10000);
    //setInterval(this.getStations, 10000);
  },
  methods: {
    async getStations() {
      this.stations = [
        {
          "name": "ONDO-GS-1",
          "userId": 1530255313,
          "lastSeen": 1686564833865,
          "version": 2105260,
          "location": [
            47.886,
            106.905
          ],
          "satellite": "ONDOSAT-OWL-1",
          "status": 1,
          "test": false,
          "confirmedPackets": 197,
          "telemetryPackets": 176,
          "autoTune": 435,
          "lastPacketTime": 1686514076335,
        },
      ]
    },
    async getSatellites() {
      this.satellites = [
        {
          "name": "ONDOSAT-OWL-1",
          "status": "Supported",
          "lat": 47.9193,
          "lng": 106.9176,
        },
      ]
    },
    formatDate(time) {
      if (!time) { return "Never" }
      return moment(time).fromNow()
    },
  },
  computed: {
    focusStations: function () {
      if (!this.focusSatellite) return this.stations
      let root = this
      return this.stations.filter((x) => x.satellite == root.focusSatellite)
    }
  }

};
</script>

<style >
.leaflet-marker-icon[src*="satellite_blue.png"] {
  z-index: 300 !important;
}

.leaflet-marker-icon[src*="station_icon_green.png"] {
  z-index: 200 !important;
}

.leaflet-marker-icon[src*="station_icon_red.png"] {
  z-index: 199 !important;
}

.hidden {
  display: none;
}

.leaflet-control-attribution {
  display: none !important;
}
</style>
