<template>
  <RightModal :showDialog="showDialog" :closeDialog="closeDialog">

    <template v-if="user" v-slot:title>
      Edit user
    </template>
    <template v-else v-slot:title>
      Create a new user
    </template>

    <v-form @submit.prevent="submitForm">
      <v-card-text>
          <v-text-field
            v-model="userName"
            label="Name"
            :rules="userNameRules"
            required
          ></v-text-field>

          <v-text-field
            v-model="email"
            label="Email"
            :rules="emailRules"
            required
          ></v-text-field>

          <v-select v-model="permission" :items="perms" label="Permission"></v-select>

          <div v-if="user" class="d-flex w-full">
            <v-btn
              v-on:click="resetpassword"
              type="button"
              color="secondary"
              class="ml-auto"
            >
              Reset password
            </v-btn>
          </div>

      </v-card-text>

      <v-card-actions class="px-4">
        <v-flex>
          <v-btn
          type="submit"
          color="primary"
          block
          class="mb-3"
          >
          Submit
        </v-btn>
        </v-flex>
      </v-card-actions>

    </v-form>

  </RightModal>
</template>

<script>
  import { useApi } from '../../hooks/useApi';

  import RightModal from '@components/Modal/RightModal';

  const userApi = useApi().user

  export default {
    props: {
      showDialog: Boolean,
      user: Object,
    },
    components: {
      RightModal
    },
    watch: {
      showDialog(newValue) {
        // Emit event to parent component when modal state changes
        this.$emit('update:showDialog', newValue);
        if (newValue) {
          this.setValues()
        }
      },
    },
    data () {
      return {
        perms: [
          { value: "user", text: "User" },
          { value: "admin", text: "Admin(big bro)" },
          { value: "zaki", text: "CEO(only see)" },
        ],
        userName: this.user?.userName ?? "",
        email: this.user?.email ?? "",
        permission: this.user?.permission ?? "user",

        userNameRules: [
          value => !!value || 'Name is required',
          value => (value && value.length <= 50) || 'Name must be less than 50 characters',
        ],
        emailRules: [
          value => !!value || 'Email is required',
          value => /.+@.+\..+/.test(value) || 'Email must be valid',
        ],
      }
    },
    methods: {
      setValues() {
        this.userName = this.user?.userName ?? ""
        this.email = this.user?.email ?? ""
        this.permission = this.user?.permission ?? "user"
      },
      async resetpassword() {
        const { success, msg } = await userApi.forgot({ email: this.email })
        if (success) {
          this.$toast(msg, {
            timeout: 3000,
            type: "success"
          });
          this.closeDialog()
        }
        else {
          this.$toast(msg, {
            timeout: 3000,
            type: "warning"
          });
        }
      },
      closeDialog() {
        this.$emit('closeDialog'); // Emit event to call the closeModalFromChild function in the parent component
      },
      async submitForm() {
        let body = {
          email: this.email,
          userName: this.userName,
          permission: this.permission
        }

        const api = this.user
                    ? userApi.put(this.user._id, body)
                    : userApi.post(body)
        const { success, msg } = await api
        if (success) {
          this.$toast(msg, {
            timeout: 3000,
            type: "success"
          });
          this.userName = ''
          this.email = ''
          this.permission = "user"

          this.closeDialog()
        }
        else {
          this.$toast(msg, {
            timeout: 3000,
            type: "warning"
          });
        }
      },
    }
  }
</script>

<style scoped>

  .v-dialog__content {
    justify-content: end;
    align-items: start;
  }

  .w-full {
    width: 100%;
  }

</style>
