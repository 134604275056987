<template>
  <div class="approve-user pt-16">
    <v-container>
      <v-row>
        <v-flex class="d-flex justify-center">
          <h1>Confirm the user</h1>
        </v-flex>
      </v-row>

      <v-container>
        <v-form @submit.prevent="submitForm">
          <v-row justify="center" class="pt-12" no-gutters>
            <v-col xs="12" sm="12" md="6" lg="4" xl="4">
              <v-text-field
                label="New password"
                v-model="formData.password"
                type="password"
                required
                :rules="passwordRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters>
            <v-col xs="12" sm="12" md="6" lg="4" xl="4">
              <v-text-field
                label="Confirm password"
                v-model="formData.confirmPassword"
                type="password"
                required
                :rules="confirmPasswordRules"
                @input="checkPasswordMatch"
              ></v-text-field>
              <small v-if="passwordMismatch" class="error--text">Passwords do not match</small>
            </v-col>
          </v-row>

          <v-row class="pt-12" justify="center" no-gutters>
            <v-btn type="submit" color="primary" :disabled="!formValid">Submit</v-btn>
          </v-row>
        </v-form>
      </v-container>

    </v-container>
  </div>
</template>

<script>
import router from "../../router"
import { useApi } from '../../hooks/useApi';

const userApi = useApi().user

export default {
  data() {
    return {
      formData: {
        password: '',
        confirmPassword: '',
      },
      formValid: false,
      passwordMismatch: false, // New property to track password mismatch

      passwordRules: [
        value => !!value || 'Password is required',
        value => (value && value.length >= 8) || 'Password must be at least 8 characters',
      ],
      confirmPasswordRules: [
        value => !!value || 'Confirm password is required',
        value => (value && value.length >= 8) || 'Confirm password must be at least 8 characters',
      ],
    }
  },
  created() {
    this.token = this.$route.params.token;
  },
  methods: {
    validateForm() {
      return (
        this.formData.password.trim() !== "" &&
        this.formData.confirmPassword.trim() !== "" &&
        this.formData.password === this.formData.confirmPassword
      );
    },
    checkPasswordMatch() {
      this.passwordMismatch = this.formData.password !== this.formData.confirmPassword;
      this.formValid = this.validateForm();
    },
    async submitForm() {
      if (this.formValid) {
        try {
          const { success, message, msg } = await userApi.confirm(this.token, this.formData)
          if (success) {
            this.$toast(message, {
              timeout: 3000,
              type: "success"
            });

            router.push("/")
          } else {
            this.$toast(msg, {
              timeout: 3000,
              type: "warning"
            });
          }
        } catch (err) {
          this.$toast("Server error. Please contact your system administrator", {
            timeout: 3000,
            type: "error"
          });
        }

      } else {
        console.log("Form submission failed. Please fill in all required fields.");
      }
    }
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.formValid = this.validateForm();
      }
    }
  },
};
</script>
