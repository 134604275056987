/**
 * Mungunii format
 */
export const moneyFormat = (number) => Intl.NumberFormat("en-US").format(number)

export function getFileName(urlP) {
    let url = new URL(urlP);
    let path = url.pathname;
    let fileName = path.substring(path.lastIndexOf('/') + 1);
    return fileName
}

/**
 * url ees file uusgej input deer onooj uguh ni
 */
export async function getFileFromUrl(url, name, defaultType = 'image/png'){
    if (!name) {
        name = getFileName(url)
    }
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
        type: data.type || defaultType,
        extraUrl: url,
    });
    return file
}

export function unitToShort(value) {

    if (!value) {
        return ""
    }

    const bytesInGB = 1024 * 1024 * 1024;
    const bytesInMB = 1024 * 1024;

    if (value >= bytesInGB) {
        return `${(value / bytesInGB).toFixed(2)} GB`
    }
    else if (value >= bytesInMB) {
        return `${(value / bytesInMB).toFixed(2)} MB`
    }
    else {
        return `${parseFloat(value).toFixed(2)} B`
    }

}
