<template>
  <v-dialog v-model="modal" max-width="540px">
    <template v-slot:activator="{ on }">
      <v-btn class="mt-2 primary" slot="activator" v-on="on">Hex Command</v-btn>
    </template>

    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h5 text-medium-emphasis">
          Command
        </div>
        <v-btn density="compact" icon @click="modal = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <!-- Only hex -->
        <v-form>
          <v-select v-model="satellite" :items="satellites" label="Select a satellite*"></v-select>
          <p class="text-start">Notice: Command prefix example is 66xx dynamic</p>
          <v-text-field v-model="command" label="Write your own HEX only command"></v-text-field>
          <v-btn @click="sendHexCommand()" color="primary">Send the command</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useApi } from '@hooks/useApi';

const satelliteApi = useApi().v2.satellite
const stationApi = useApi().station


export default {
  name: "HexCommand",
  props: [
    "station",
    "modemConf"
  ],
  data() {
    return {
      modal: null,
      satellite: '',
      command: '',
      satellites: null
    }
  },
  beforeMount() {
    this.getVersion2Satellites();
  },
  methods: {
    async getVersion2Satellites() {
      const { success, data } = await satelliteApi.get();

      if (success)
      {
        this.satellites = data.map(satellite => (
          {
            text: satellite.name,
            value: satellite._id
          }
        ));
      }
    },
    async sendHexCommand() {
      let params = {
        satelliteId: this.satellite,
        command: this.command
      }
      // TODO: Satellite modemConf
      try {
        await stationApi.sendHexCommand(this.$route.params.id, params)
        this.$toast("The command was sent", {
          timeout: 3000,
          type: "success"
        });
      } catch (err) {
        console.log(JSON.stringify(err))
        this.$toast("An error occurred while sending the command", {
          timeout: 3000,
          type: "error"
        });
      }
      this.$emit("sent");
      this.modal = false
    }
  }
}
</script>

<style>

</style>
