<template>
  <div class="user">
    <v-container>
      <v-row class="ml-1 mt-2">
        <v-col cols="12" sm="12" md="4" lg="3" class="mt-1">
          <v-text-field v-model="searchTxt" hide-details outlined dense clearable label="Find" prepend-inner-icon="mdi-magnify"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <!-- <v-flex xs12 sm12>
          <h1 class="heading">User list</h1>
        </v-flex> -->

        <v-container v-if="user.permission === 'admin'" fluid>
          <v-row
            justify="end"
          >
            <v-sheet class="ma-8">
              <v-btn
                color="primary"
                @click="openModal"
                >
                Create user
              </v-btn>
            </v-sheet>
          </v-row>
        </v-container>

        <!-- Users list table -->
        <v-skeleton-loader
          :loading="isLoading"
          style="width: 100%;"
          type="table"
        >
          <template>
            <div style="width: 100%;">
              <ve-table
                class="v-responsive"
                max-height="calc(100vh - 400px)"
                :table-data="users"
                :border-around="true"
                :columns="columns"
              />
              <div class="table-pagination">
                <ve-pagination
                  :total="pagination.total"
                  :page-index="page"
                  :page-size="limit"
                  :pageSizeOption="[10, 20, 50, 100]"
                  @on-page-number-change="pageNumberChange"
                  @on-page-size-change="pageSizeChange"
                />
              </div>
            </div>
          </template>
        </v-skeleton-loader>
      </v-row>

      <CreateUserModal :user="selectUser" :showDialog="showDialog" @closeDialog="closeModal" />

    </v-container>
  </div>
</template>

<script>
const axios = require("axios");

import store from '../store';
import { useApi } from '../hooks/useApi';

import CreateUserModal from './user/CreateUser.vue'

const userApi = useApi().user

export default {
  components: {
    CreateUserModal
  },
  computed: {
    user: function() {
      return store.state.auth.user
    }
  },
  data() {
    return {
      showDialog: false,
      isLoading: true,

      searchTxt: '',
      timeoutId: null,

      // initial variables for a data table
      pagination: {
        start: 1,
        end: 1,
        total: 0,
        pageCount: 1,
        nextPage: 1,
        prevPage: 1,
      },
      page: 1,
      limit: 10,

      selectUser: null,
      users: [],
      columns: [
          {
            field: "userName",
            key: "userName",
            title: "name",
            align: "center"
          },
          {
            field: "email",
            key: "email",
            title: "email",
            renderBodyCell: ({ row }) => {
              // zuvhun admin zasdag
              if (this.user.permission === "admin")
                return (
                  <a onClick={() => this.handleItem(row)}>{row.email}</a>
                )
              else
                return row.email
            },
            align: "left"
          },
          {
            field: "Permission",
            key: "permission",
            title: "Permission",
            align: "center",
            renderBodyCell: ({ row }) => {
              const returnItem = {
                "admin": (
                  <span class="badge green">Admin</span>
                ),
                "user": (
                  <span class="badge secondary">User</span>
                ),
                "zaki": (
                  <span class="badge secondary">CEO</span>
                )
              }

              return returnItem[row.permission]
            }

          },
          {
            field: "isActive",
            key: "isActive",
            title: "active",
            align: "center",
            renderBodyCell: ({ row }) => {
              if (row.isActive) {
                return (
                  <span class="badge primary">Active</span>
                )
              }

              return <span class="badge warning">Inactive</span>
            }

          },
          {
            field: "createdAt",
            key: "createdAt",
            title: "created at",
            renderBodyCell: ({ row }) => {
              let date = this.convertDate(row.createdAt)

              return date
            }
          },
        ],
    }
  },
  beforeMount() {
    this.getUsers();
  },
  methods:{
    handleItem(row) {
      this.selectUser = row
      this.showDialog = true
    },
    // page number change
    pageNumberChange(pageIndex) {
      this.page = pageIndex
      this.getUsers()
    },

    // page size change
    pageSizeChange(pageSize) {
      this.limit = pageSize
      this.getUsers()
    },

    /**
     * User-ийн жагсаалтыг авах функц
     */
    async getUsers() {
      this.isLoading = true
      try {
        const { data, pagination } = await userApi.get(this.page, this.limit, "userName", this.searchTxt);

        this.users = data
        this.isLoading = false
        this.pagination = pagination

      } catch (err) {
        console.error(err);
      }
    },
    /**
     * String date-ийн утгийг slash-тай date-ийн format-руу хөрвүүлэх функц
     * @param {String} value string type-тэй date-ийн утгыг явуулах
     */
    convertDate(value) {
      let returnValue = ''
      try {
        let date = new Date(value)
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        returnValue = [year, month, day].join('/');
      } catch (err) {
        console.error(err);
      } finally {
        return returnValue
      }
    },
    openModal() {
      this.showDialog = true
    },
    closeModal() {
      this.showDialog = false
      this.selectUser = null
      this.getUsers()
    }
  },
  watch: {
    searchTxt() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.getUsers()
      }, 1500);

    },
  }
}
</script>

<style>
  .ve-table {
    margin: 0 16px;
  }

  .table-pagination {
      margin-top: 20px;
      text-align: right;
    }

  .badge {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: bold;
    color: white;
  }

  .primary {
    background-color: #ff0099;
  }

  .warning {
    background-color: #ffcc00;
  }
</style>
