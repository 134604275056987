<template>
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="500"
      content-class="rightmodal"
    >
      <v-card min-height="100vh">
        <v-card-title class="text-h5">
          <slot name="title">Default Card title</slot>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <slot></slot>

      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    name: "RightModal",
    props: {
      showDialog: Boolean,
      closeDialog: Function,
    },
  }
</script>

<style scoped>

  .v-dialog__content {
    justify-content: end;
    align-items: start;
  }

</style>
