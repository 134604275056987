<template>
  <v-app style="background-color: #101219;">
    <Navbar :isAuthenticated="isAuthenticated" :user="user" />
    <v-main style="height:100%;">

      <router-view></router-view>

      <div :style="{ paddingBottom: paddingSize }"></div>
      <v-footer class="mt-12 pa-3" color="black" padless absolute>
        <v-row justify="center" no-gutters>
          <v-btn v-for="  link   in   links  " :key="link.url" color="white" text rounded class="my-2 mx-1"
            @click="navigate(link.url)">
            <v-icon class="mr-2">{{ link.icon }}</v-icon> {{ link.name }}
          </v-btn>
          <v-col class=" py-4 text-center white--text" cols="12">
            {{ new Date().getFullYear() }} — <strong>Ondo Ground Station</strong>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import store from "./store"

import Navbar from './components/Navbar.vue';

export default {
  name: 'App',

  components: {
    Navbar,
  },
  computed: {
    isAuthenticated: function () {
      return store.state.auth.isAuthenticated
    },
    user: function () {
      return store.state.auth.user
    }
  },
  data() {
    return {
      paddingSize: "150px",
      links: [
        // {icon:"mdi-github", name:' GitHub',url:"https://github.com/G4lile0/tinyGS"},
        // {icon:"mdi-file-document-multiple-outline", name:' Wiki',url:"https://github.com/G4lile0/tinyGS/wiki"},
        // {icon:"mdi-map-marker-question-outline", name:' FAQ',url:"https://github.com/G4lile0/tinyGS/wiki/FAQ"},
        // {icon:"mdi-forum", name:' Telegram',url:"https://t.me/joinchat/DmYSElZahiJGwHX6jCzB3Q"},
      ],
    }
  },
  updated() {
    if (window.location.pathname === "/home") {
      this.paddingSize = "0px"
    } else {
      this.paddingSize = "150px"
    }
  },
  methods: {
    navigate(url) {
      window.location = url;
    },
  }
};
</script>

<style>
.ve-table-body-td {
  background-color: #121212 !important;
  font-size: 0.9rem !important;
  color: #fff !important;
}

.ve-table-header-th {
  background-color: #121212 !important;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff !important;
}

.rightmodal.v-dialog {
    margin: 0px !important;
}
.rightmodal.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
  min-height: 100%;
}
</style>
